import { gql } from "@apollo/client";

export const GET_ADMIN = gql`
query GetAdmin {
  getAdmin {
    email
    id
    role
  }
}`

export const GET_ADMINS = gql`
query GetAdmins {
  getAdmins {
    email
    id
    role
    name
  }
}`

export const GET_CHARTER = gql`
query GetCharters {
  getCharters {
    id
    country
    nationalIncome
    nationalExpenditures
    localIncome
    localExpenditures
    noYFCCenters
    noYoungDirectsMinistry
    noYoungMediasMinistry
    noFollowDirectsMinistry
    noFollowMediasMinistry
    noDiscipleship
    noYoungLeaders
    noMissionTeam
    noParticipantsTeam
    noPrayerIntercessors
    noDenominations
    noChurchPartner
    serviceVolunteers
    noPaidService
    noRegularService
    noStaffOtherNation
    noBoardMember
    noYoungResponse
    socialPlatform
    ministryActivity
    preRaisedOwn
    user {
      exchange
      country
      region
      area
    }
  }
}`

export const GET_QUESTION = gql`
query GetQuestions($year: String) {
  getNations {
    id
    name
    area {
      name
    }
    region {
      name
    }
  }
  getQuestions(year: $year) {
    id
    country
    nationalIncome
    nationalExpenditures
    localIncome
    localExpenditures
    noYFCCenters
    noYoungDirectsMinistry
    noYoungMediasMinistry
    noFollowDirectsMinistry
    noFollowMediasMinistry
    noDiscipleship
    noYoungLeaders
    noMissionTeam
    noParticipantsTeam
    noPrayerIntercessors
    noDenominations
    noChurchPartner
    serviceVolunteers
    noPaidService
    noRegularService
    noStaffOtherNation
    noBoardMember
    noYoungResponse
    socialPlatform
    ministryActivity
    preRaisedOwn
    nation {
      id
      name
      exchange
      area {
        name
      }
      region {
        name
      }
    }
  }
}`

export const GET_ALL_USERS = gql`
query GetCharterUser {
  getCharterUser {
    name
    country
    exchange
    currency
    region
    area
    email
    status
    NDEmail
    NDName
    RDEmail
    RDName
    ADEmail
    ADName
    role
  }
}`

export const GET_USERSS = gql`
query GetUsers {
  getUsers {
    id
    name
    email
    status
    language
  }
}`

export const GET_SCHEDULE_USERS = gql`
query GetActiveUsers {
  getActiveUsers {
    id
    name
    email
    language
    nations {
      id
      name
      nd {
        id
      }
      bc {
        id
      }
      cw{
        id
      }
    }
    regions {
      id
      rd {
        id
      }
    }
    areas {
      ad {
        id
      }
    }
  }
}`

export const GET_USERS = gql`
query GetUsers {
  getUsers {
    id
    name
    email
    status
    language
    verified
    admin
    nations {
      name
      id
      role
      nd {
        id
      }
      bc {
        id
      }
      cw{
        id
      }
    }
    regions {
      name
      id
    }
    areas {
      name
      id
    }
  }
  getAreas {
    id
    name
  }
}`

export const GET_USER = gql`
query GetUsers {
  getUsers {
    id
    name
  }
  getAreas {
    id
    name
  }
  getRegions {
    id
    name
  }
}`

export const GET_NATIONS = gql`
query GetCharterAnuals {
  getCharters {
    id
    country
    user{
      name
      NDName
    }
  }
  getCharterAnuals {
    id
    directorSignature
    chairSignature
    anualCharteringId
  }
}`

export const GET_NATION = gql`
query GetNations($year: String) {
  getNations(year: $year) {
    id
    name
    exchange
    currency
    language
    status
    allStatus
    nd {
      id
      name
    }
    bc {
      id
      name
    }
    cw {
      id
      name
    }
    region{
      id
      name
    }
  }
  getQuestions(year: $year) {
    id
    nationId
    anual(year: $year) {
      id
      directorSignature
      chairSignature
      rdSignature
      paying_how
      payment_comment
      date_submitted
    }
  }
}`

export const GET_ONE_NATION = gql`
query GetNation($getNationId: String, $year: String) {
  getNation(id: $getNationId, year: $year) {
    id
    name
    exchange
    currency
    language
    status
    nd {
      id
      name
    }
    bc {
      id
      name
    }
    cw {
      id
      name
    }
  }
  getQuestions(year: $year) {
    id
    nationId
    anual(year: $year) {
      id
      directorSignature
      chairSignature
      rdSignature
      paying_how
      payment_comment
      date_submitted
    }
  }
}`

export const GET_AREAS = gql`
query GetAreas {
  getAreas {
    ad {
      name
      id
    }
    id
    name
  }
}`

export const GET_REGIONS = gql`
query GetRegions {
  getRegions {
    id
    name
    rd {
      id
      name
    }
    area{
      id
      name
    }
  }
}`

export const GET_REPORT = gql`
query GetCharterNation($getCharterNationId: String) {
  getCharterNation(id: $getCharterNationId) {
    id
    userId
    country
    isConstitution
    isCompliance
    isAgreement
    isReg
    regName
    regNumber
    regType
    regUptoDate
    regProcess
    orgUmbrella
    isPublicName
    nameUsed
    isbrandComp
    mailAddress
    streetAddress
    conTele
    conEmail
    conWebsite
    directorName
    directorJobTitle
    directorEmployStatus
    directorPhone
    directorEmail
    chairName
    chairPhone
    chairEmail
    noBoardMember
    boardMemName
    boardMemPhone
    boardMemEmail
    canVote
    isBoardMemND
    isStaff
    boardMemPosition
    boardMemYears
    isBoardMoreFamily
    isBoardMemReElect
    boardMemTerm
    boardMemConsTerm
    ADMeetParticipate
    isDirectorAppoinment
    isBoardDirectorDiscr
    boardMeeting
    isBankAccount
    bankDetail
    isFinancialIntegrity
    financialStart
    financialEnd
    isFinancialState
    financialPrepare
    isfinancialPolicy
    isFinancialStandards
    listGFP
    is200k
    isExternalAuditIFRS
    noFinancialAuditIFRS
    isExternalAudit
    noFinancialAudit
    isStaffRetirement
    isContributionsUptoDate
    landDiscr
    landDate
    landOwner
    isLandBoardApprove
    nationalIncome
    isNationalIncome
    localIncome
    isLocalIncome
    nationalExpenditures
    isNationalExpenditures
    localExpenditures
    isLocalExpenditures
    preRaisedOwn
    supportRaiseNation
    isRaisePermission
    isFinancialGift
    isApprovedBudget
    approvedBudgetFile
    isFundRaising
    fundRaisingFile
    serviceVolunteers
    noPaidService
    noRegularService
    ministryActivity
    noMinistries
    ministryNemr
    isMedia
    socialPlatform
    aboutSocialPlatform
    broadcastPlatform
    platformAdditional
    isMinistryPlan
    ministryPlanFile
    ministryGoal
    noYFCCenters
    noYoungDirectsMinistry
    noYoungMediasMinistry
    noFollowDirectsMinistry
    noFollowMediasMinistry
    noYoungResponse
    noNoCommitment
    noCommitment
    noDiscipleship
    noConnectLocalChurch
    ministryCollectMethod
    noYoungLeaders
    noMissionTeam
    noParticipantsTeam
    teamNation
    noTeamOtherNation
    noParticipantsOtherNation
    otherTeamNation
    noChurchPartner
    noDenominations
    noStaffOtherNation
    noStaffYourNation
    noPrayerIntercessors
    lastGAYear
    isLastGADir
    isLastGAChair
    isCompleteReview
    isStrategicPlan
    isPlanCurrentVersion
    isDirJobDiscr
    isDirJobCurrentVersion
    isBoardFinancePolicy
    isPolicyCurrentVersion
    isFundRaisePlan
    isFundRaiseCurrentVersion
    isBoardManual
    isManuelCurrentVersion
    isStaffPolicyManual
    isStaffPolicyCurrentVersion
    isChildPolicy
    isChildPolicyCurrentVersion
    isSensitive
    isYFCIName
    YFCIName
    isYFCILogo
    YFCILogo
    isTerminology
    terminology
    isYFCIWebsiteRemove
    YFCIWebsiteRemove
    isSocialRefAvoid
    socialRefAvoid
    isEmailAvoid
    emailAvoid
    isDirNameAvoid
    dirNameAvoid
    isSharePermit
    sharePermit
    isEncryptCom
    encryptCom
    isGlobalDirectory
    globalDirectory
    isListWebsite
    listWebsite
    isReportOffline
    reportOffline
    isFinanceInstructions
    financeInstructions
    isSafeHouse
    safeHouse
    isHaveSafeHouse
    haveSafeHouse
    isTraumaTrained
    traumaTrained
    lastAuditFile
    nextBudgetFile
    constitutionFile
    payment
    paymentDate
  }
}`

export const GET_EMAILS = gql`
query GetEmails {
  getEmails {
    email
    id
    title
    json
    subject
    language
  }
  getSchedule {
    id
    title
    date
    mails {
      email
    }
  }
}`

export const GET_SCHEDULES = gql`
query GetSchedule {
  getSchedule {
    id
    title
    date
    mails
  }
}`

export const GET_LOGS = gql`
query GetLogs($getLogsId: String) {
  getLogs(id: $getLogsId) {
    date
    id
    log
    type
  }
}`